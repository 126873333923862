<template>
  <div class="flex space-x-[16px]">
    <a-input
      v-model:value="searchQuery"
      :placeholder="t('common.search')"
      class="bg-ems-tag1 border-none h-[38px] rounded-lg"
    >
      <template #prefix>
        <cds-feather-icons type="search" size="14" />
      </template>
    </a-input>
  </div>
  <div
    ref="scrollContainer"
    class="mt-[24px] overflow-auto h-[75%] pr-[4px] hidden-scroll"
  >
    <div
      v-for="(entry, entryIndex) in filteredListContactsFormat"
      :key="entryIndex"
    >
      <div>
        <div class="flex items-center space-x-[10px]">
          <img v-if="entry.listData.length>0" alt="icon" :src="entry.icon" />
          <p class="font-semibold text-white text-[16px]">{{ entry.listData.length>0? t(`${entry.value}`):'' }}</p>
        </div>
        <div v-for="(item, index) in entry.listData" :key="index">
          <div
            @click="handleClick(item)"
            :class="`w-full h-[96px]  mt-[11px] ${
              idSelect === item.userId ||
              hoveredItem === item.userId  ? 'bg-ems-gray700' : ''
            } flex items-center px-[16px] space-x-[23px] rounded-lg relative cursor-pointer`"
            @mouseenter="hoveredItem = item.userId"
            @mouseleave="hoveredItem = ''"
          > 
            <div class="rounded-full w-[64px] h-[64px] bg-white relative">
              <div
                class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
              >
                <img alt="avatar" :src="item.avatar" class="w-ful h-auto" />
              </div>
              <div
                v-if="item.userStatus.online"
                class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
              ></div>
            </div>
            <div class="space-y-[8px]">
              <p class="text-white font-semibold text-[16px]">
                {{ item.fullName }}
              </p>
              <div class="flex items-center space-x-[19px] justify-start">
                <p
                  class="text-ems-gray200 font-normal text-[14px] truncate max-w-[250px] py-2"
                >
                  {{
                    item.userStatus.online
                      ? t('coordinator.online')
                      : t('coordinator.offline')
                  }}
                </p>
                <p class="text-ems-gray200 font-normal text-[12px]"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {TAB_COORDINATION,PEER_TYPE,TAB_WALKIE} from '@/util/common-constant.js'
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { onUnmounted } from 'vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const listContacts = computed(() => state.coordinator.listContacts);
const listContactsInit = computed(() => state.coordinator.listContactsInit);
const idSelect = ref('');
const router = useRouter();
const route = useRoute();
const searchQuery = ref('');
const filteredListContactsFormat = ref([]);
const scrollContainer = ref(null);
const hoveredItem = ref('');
const initScroll = ref(true);

onMounted(async () => {
  dispatch('coordinator/getListContacts').then(()=>{
    const item = listContactsInit.value.find(
      (item) => item.userId === route.query.id
    );
    if (initScroll.value) {
      if (item) {
        handleClick(item);
      } else {
        handleClick(listContactsInit.value[0]);
      }
      initScroll.value = false;
    }
  });
});
const filterContacts = () => {
  if (!searchQuery.value) {
    return listContacts.value;
  }
  return listContacts.value.map((group) => {
    return {
      ...group,
      listData: group.listData.filter((item) =>
        item.fullName.toLowerCase().includes(searchQuery.value.toLowerCase())
      ),
    };
  });
};
watch([searchQuery, listContacts], () => {
  filteredListContactsFormat.value = filterContacts();
});

const handleClick = (item) => {
  let query = {
    id: item.userId,
    type: PEER_TYPE.USER,
    tab_list:TAB_COORDINATION.CONTACT,
    tab_walkie:TAB_WALKIE.WALKIE,
  };
  dispatch('coordinator/handleSetParams', query);
  idSelect.value = item.userId;
  dispatch('coordinator/getDialogsId', item.userId).then((dialogId) => {
    const isChated = !!dialogId;
    dispatch('coordinator/getMessagesByDialogs', {
      dialogId: dialogId,
      dialogType: 1,
      isChated:isChated
    });
  });
  let value = {
    otherInfo: {
      peerAvatarUrl: item.avatar,
      peerId: item.userId,
      peerName: item.fullName,
      peerType: 1,
      status: item.userStatus.online,
      like:item.like,
    },
    tab:TAB_COORDINATION.CONTACT,
  };
  dispatch('coordinator/selectValue', value);
  dispatch('coordinator/getListStatus', item.userId);
};
</script>
<style>

</style>
