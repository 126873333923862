<template>
  <div class="w-full mt-[23px] input-search-phone px-[20px] bg-ems-gray800 h-[90vh] pb-5">
    <ListContact/>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ListContact from './components/list-contacts.vue';
const userName = ref('');
</script>
<style lang="scss">
.input-search-phone .ant-input-affix-wrapper > input.ant-input {
  background: #373737;
  color: #FFFFFF;
}
.input-search-phone .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #C4C4C4;
}
</style>
